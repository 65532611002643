import React from 'react';
import { toast } from 'react-toastify';
import includes from 'lodash/includes';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import lowerCase from 'lodash/lowerCase';
import get from 'lodash/get';
import isString from 'lodash/isString';
import noop from 'lodash/noop';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import { i18n } from '../../i18n';

const warningKeys = ['generic_action_cancelled'];

export const handleError = (error) => {
  const isAuthError =
    (error.response?.status === 401 &&
      !/token\/refresh/gi.test(error.config?.url)) ||
    false;
  const errors = isString(error.response?.data)
    ? error.response?.data
    : error.response?.data?.errors;
  const warnMessages = map(warningKeys, (key) => i18n.t(key));
  let errorHandler;

  switch (true) {
    case includes(warnMessages, error.message):
      errorHandler = toast.warn;
      break;
    case isAuthError:
      errorHandler = noop;
      break;
    default:
      errorHandler = toast.error;
  }

  if (errors) {
    if (isString(errors)) {
      errorHandler(errors);
    } else {
      forEach(errors, (currentError) => {
        if (currentError.code) {
          const { code, ...variables } = currentError;
          const messageByCode = startCase(lowerCase(code));
          errorHandler(i18n.t([code, messageByCode], variables));
        } else if (isString(get(currentError, '0'))) {
          errorHandler(get(currentError, '0', ''));
        } else {
          errorHandler(i18n.t('errorOfferManagerApierror'));
          console.error(currentError);
        }
      });
    }
  } else {
    errorHandler(error.message);
  }
};

export const ErrorHelper = ({ error }) => {
  if (!error && !(error?.message || error?.response)) {
    return null;
  }
  const warnMessages = map(warningKeys, (key) => i18n.t(key));

  if (includes(warnMessages, error.message)) {
    return null;
  }

  const errors = error.response?.data?.errors;

  if (errors) {
    let messages = [];

    forEach(errors, (currentError) => {
      if (currentError.code) {
        const { code, ...variables } = currentError;
        messages.push(i18n.t(code, variables));
      } else if (isString(get(currentError, '0'))) {
        messages.push(get(currentError, '0', ''));
      } else {
        messages.push(i18n.t('errorOfferManagerApierror'));
        console.error(currentError);
      }
    });

    messages = uniq(messages);

    if (messages.length === 0) {
      return null;
    }

    return (
      <div className='row l-margin-top-20'>
        <div className='col-xs-12'>
          <div className='alert-message alert alert-danger'>
            <ul>
              {map(messages, (message) => {
                return <li key={message}>{message}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='row l-margin-top-20'>
      <div className='col-xs-12'>
        <div className='alert-message alert alert-danger'>{error.message}</div>
      </div>
    </div>
  );
};
