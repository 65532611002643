// import values from 'lodash/values';
import { ROLES, isProd } from './core/constants';

const { User, Service, CountryManager, SuperAdmin, Partner, Marketing, Sales } =
  ROLES;

// const ALL_VISITORS = values(ROLES);
const ALL_OM_USERS = [
  User,
  Service,
  CountryManager,
  SuperAdmin,
  Partner,
  Marketing,
  Sales,
];

export const ConnectedACLRules = {
  ConfigurationPage: [Service, SuperAdmin],
  MyProfilePage: [Service, SuperAdmin],
  CountriesPage: [Service, SuperAdmin],
  CountriesPageCreate: [Service],
  PosPage: [Service, CountryManager, SuperAdmin],
  PosPageCreate: [Service],
  PosPageEditFields: [Service],
  UsersPage: [Service, SuperAdmin],
  OffersPage: ALL_OM_USERS,
  OffersArchivesPage: ALL_OM_USERS,
  OffersArchivePage: ALL_OM_USERS,
  OffersSearchAllPosPage: ALL_OM_USERS,
  OffersPageAddingOffers: [
    User,
    Service,
    CountryManager,
    SuperAdmin,
    Marketing,
  ],
  OffersPageAddingOffersFromFile: isProd
    ? []
    : [User, Service, CountryManager, SuperAdmin, Partner, Marketing],
  ResetPricesPage: [
    User,
    Service,
    CountryManager,
    SuperAdmin,
    Partner,
    Marketing,
  ],
  PublishOffersBulkPage: [
    User,
    Service,
    CountryManager,
    SuperAdmin,
    Partner,
    Marketing,
  ],
  ManagePortalPage: [SuperAdmin, Marketing],
  SEOPage: [Service, CountryManager, SuperAdmin, Marketing],
  UploadPhotos: [User, Service, CountryManager, SuperAdmin, Marketing],
  TransactionsPage: [Service, SuperAdmin],
  TranslationsPage: [Service, SuperAdmin],
  LabelsPage: [Service, SuperAdmin, Marketing, CountryManager],
  PartnerCompaniesPage: isProd ? [] : [Service, SuperAdmin],
  VehicleGroupsPage: isProd ? [] : [Service, SuperAdmin],
  PromotionParametersPage: isProd ? [] : [Service, SuperAdmin],
  MotorTradeLogsPage: [Service, SuperAdmin],
  ReportsDraftsPage: [User, Service, CountryManager, SuperAdmin, Marketing],
  ReportsPage: ALL_OM_USERS,
  StockTrafficReportsPage: [Service, SuperAdmin, CountryManager],
};
