import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';
import startCase from 'lodash/startCase';

export const DEFAULT_PAGE_SIZE = 80;

// example FE instances urls for regex:
// https://offermanager.autoselect.makolab.com/
// https://offermanager-autoselect-dev.makolab.com/
// https://offermanager-autoselect-pre.makolab.com/
// https://offermanager-autoselect-uat.makolab.com/
// https://arval-dev-euw-appservice-backoffice-front.azurewebsites.net/
const hasEnv =
  window.location.origin.match(
    /^https?:\/\/offermanager[.-]autoselect(?:-(dev|pre|uat|qa))?\.makolab\.com$/
  ) ||
  window.location.origin.match(
    /^https?:\/\/arval(?:-(dev|pre|uat|qa))?-euw-appservice-backoffice-front\.azurewebsites\.net$/
  );

// CI should launch as 'dev'
const isProduction =
  process.env.NODE_ENV === 'production' && process.env.REACT_APP_CI !== 'true';

const defaultEnv = isProduction ? 'prod' : 'uat';
export const ENV = hasEnv && !isEmpty(hasEnv[1]) ? hasEnv[1] : defaultEnv;
export const isUat = ENV === 'uat';
export const isProd =
  isProduction && (!hasEnv || ENV === 'prod' || ENV === 'preprod');

// replaces https://arval-dev-... to https://arval-prod-... etc., based on current window.origin
// we need to support multiple instances
export const replaceEnv = (url) =>
  replace(
    url,
    /^(https?:\/\/arval-)([^-]+)(-.+)$/,
    (match, $1, $2, $3) => `${$1}${ENV}${$3}`
  );

// please keep values the same as keys
export const ROLES = {
  SuperAdmin: 'Super Admin',
  Service: 'Service',
  CountryManager: 'Country Manager',
  Cms: 'Cms',
  User: 'User',
  Anonymous: 'Anonymous',
  Partner: 'Partner',
  Marketing: 'Marketing',
  Sales: 'Sales',
};

export const getRole = (role) => ROLES[replace(startCase(role), /\s+/gi, '')];

export const USERS_TYPES_OPTIONS = [
  {
    value: 'Internal',
    translation: 'users_type_internal',
  },
  {
    value: 'Partner',
    translation: 'users_type_partner',
  },
];

export const LOCALES = {
  'en-GB': {
    id: 'en-GB',
    lang: 'en',
    label: 'English',
    fetchDateFnsLocale: () => import('date-fns/locale/en-GB'),
  },
  'es-ES': {
    id: 'es-ES',
    lang: 'es',
    label: 'Spanish',
    fetchDateFnsLocale: () => import('date-fns/locale/es'),
  },
  'fr-FR': {
    id: 'fr-FR',
    lang: 'fr',
    label: 'French',
    fetchDateFnsLocale: () => import('date-fns/locale/fr'),
  },
  'it-IT': {
    id: 'it-IT',
    lang: 'it',
    label: 'Italian',
    fetchDateFnsLocale: () => import('date-fns/locale/it'),
  },
  'be-BE': {
    id: 'be-BE',
    lang: 'be',
    label: 'Flemish',
    fetchDateFnsLocale: () => import('date-fns/locale/be'),
  },
  'nl-NL': {
    id: 'nl-NL',
    lang: 'nl',
    label: 'Dutch',
    fetchDateFnsLocale: () => import('date-fns/locale/nl'),
  },
};

// WARNING: changing this will reset everybody's default language settings
export const I18N_LS_KEY = 'i18nextLng';

// from http://emailregex.com/
export const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\da-z\-]+\.)+[a-z]{2,}))$/i;

// these labels are used as ids for backend to change hidden flag
export const STEPS = [
  {
    label: 'edit_offer_vehicle_title',
    hidden: false,
    parseUrl: (offerId) => `/offers/edit/${offerId}`,
  },
  {
    label: 'edit_offer_equipment_title',
    hidden: false,
    parseUrl: (offerId) => `/offers/edit/${offerId}/equipment`,
  },
  {
    label: 'edit_offer_damages_title',
    hidden: false,
    parseUrl: (offerId) => `/offers/edit/${offerId}/damages`,
  },
  {
    label: 'edit_offer_maintenance_title',
    hidden: false,
    parseUrl: (offerId) => `/offers/edit/${offerId}/maintenance`,
  },
  {
    label: 'edit_offer_finance_title',
    hidden: false,
    parseUrl: (offerId) => `/offers/edit/${offerId}/finance`,
  },
  {
    label: 'edit_offer_upload_photos',
    hidden: false,
    parseUrl: (offerId) => `/offers/edit/${offerId}/upload-photos`,
  },
  {
    label: 'edit_offer_assign_photos',
    hidden: false,
    parseUrl: (offerId) => `/offers/edit/${offerId}/assign-photos`,
  },
  {
    label: 'edit_offer_portals_title',
    hidden: false,
    parseUrl: (offerId) => `/offers/edit/${offerId}/portals`,
  },
  {
    label: 'edit_offer_hot_spots_title',
    hidden: true,
    parseUrl: (offerId) => `/offers/edit/${offerId}/hotspots`,
  },
  {
    label: 'edit_offer_exports_title',
    hidden: false,
    parseUrl: (offerId) => `/offers/edit/${offerId}/exports`,
  },
];

export const PURCHASE_OPTIONS = [
  { name: 'table_seo_sale', value: 'Sale' },
  { name: 'table_seo_release', value: 'Release' },
];

export const OFFERS_TABLE_TRANSLATIONS_KEYS = {
  offerId: 'table_id',
  make: 'edit_offer_vehicle_make',
  model: 'edit_offer_vehicle_model',
  vin: 'edit_offer_vehicle_vin',
  registrationNumber: 'edit_offer_vehicle_registration',
  salePrice: 'edit_offer_finance_sale_price',
  motorTradeEventName: 'table_mt_event',
  numberOfPictures: 'table_photos',
  createdBy: 'table_created_by',
  createdAt: 'table_created_at',
  firstExportAt: 'table_first_publish',
  marketingLabel: 'table_marketing_label',
  reservationLabel: 'table_reservation_label',
  status: 'table_status',
  mileage: 'table_mileage',
  originalLocation: 'table_original_location',
  reservedBy: 'table_reserved_by',
  buttons: 'table_buttons',
  lastExportAtAutocasion: 'table_last_export_at_autocasion',
  lastExportAtVogest: 'table_last_export_at_vogest',
  lastExportAtArvalEN: 'table_last_export_at_arval_en',
  lastExportAtArvalES: 'table_last_export_at_arval_es',
  lastExportAtArvalFR: 'table_last_export_at_arval_fr',
  lastExportAtUbiflow: 'table_last_export_at_ubiflow',
  lastExportAtCaixa: 'table_last_export_at_caixa',
  lastExportAtAutoTrader: 'table_last_export_at_auto_trader',
  lastExportAtCetelem: 'table_last_export_at_cetelem',
  lastExportAtArvalIT: 'table_last_export_at_arval_it',
  lastExportAtArvalBeEnglish: 'table_last_export_at_arval_be_english',
  lastExportAtArvalBeFrench: 'table_last_export_at_arval_be_french',
  lastExportAtArvalBeNiderland: 'table_last_export_at_arval_be_niderland',
  lastExportAtAACars: 'table_last_export_at_aa_cars',
  lastExportAtArvalBE: 'table_last_export_at_arval_be',
  lastExportAtArvalPL: 'table_last_export_at_arval_pl',
  lastExportAtArvalNL: 'table_last_export_at_arval_nl',
  lastExportAtArvalFI: 'table_last_export_at_arval_fi',
  lastExportAtOtoMoto: 'table_last_export_at_oto_moto',
  lastExportAtArvalPT: 'table_last_export_at_arval_pt',
  lastExportAtArvalBeReleaseEnglish:
    'table_last_export_at_arval_be_release_english',
  lastExportAtArvalBeReleaseFrench:
    'table_last_export_at_arval_be_release_french',
  lastExportAtArvalBeReleaseNiderland:
    'table_last_export_at_arval_be_release_niderland',
  lastExportAtArvalSE: 'table_last_export_at_arval_se',
  lastExportAtArvalDE: 'table_last_export_at_arval_de',
  lastExportAtArvalTR: 'table_last_export_at_arval_tr',
  lastExportAtBrokersFR: 'table_last_export_at_brokers_fr',
};

export const OFFERS_TABLE_DEFAULT_SETTINGS = [
  {
    key: 'offerId',
    visible: true,
  },
  {
    key: 'make',
    visible: true,
  },
  {
    key: 'model',
    visible: true,
  },
  {
    key: 'vin',
    visible: true,
  },
  {
    key: 'registrationNumber',
    visible: true,
  },
  {
    key: 'salePrice',
    visible: true,
  },
  {
    key: 'numberOfPictures',
    visible: true,
  },
  {
    key: 'motorTradeEventName',
    visible: true,
  },
  {
    key: 'createdBy',
    visible: true,
  },
  {
    key: 'createdAt',
    visible: true,
  },
  {
    key: 'firstExportAt',
    visible: true,
  },
  {
    key: 'marketingLabel',
    visible: true,
  },
  {
    key: 'reservationLabel',
    visible: true,
  },
  {
    key: 'status',
    visible: true,
  },
  {
    key: 'mileage',
    visible: true,
  },
  {
    key: 'originalLocation',
    visible: true,
  },
  {
    key: 'reservedBy',
    visible: true,
  },
  {
    key: 'buttons',
    visible: true,
  },
  {
    key: 'lastExportAtAutocasion',
    visible: false,
  },
  {
    key: 'lastExportAtVogest',
    visible: false,
  },
  {
    key: 'lastExportAtArvalEN',
    visible: false,
  },
  {
    key: 'lastExportAtArvalES',
    visible: false,
  },
  {
    key: 'lastExportAtArvalFR',
    visible: false,
  },
  {
    key: 'lastExportAtUbiflow',
    visible: false,
  },
  {
    key: 'lastExportAtCaixa',
    visible: false,
  },
  {
    key: 'lastExportAtAutoTrader',
    visible: false,
  },
  {
    key: 'lastExportAtCetelem',
    visible: false,
  },
  {
    key: 'lastExportAtArvalIT',
    visible: false,
  },
  {
    key: 'lastExportAtArvalBeEnglish',
    visible: false,
  },
  {
    key: 'lastExportAtArvalBeFrench',
    visible: false,
  },
  {
    key: 'lastExportAtArvalBeNiderland',
    visible: false,
  },
  {
    key: 'lastExportAtAACars',
    visible: false,
  },
  {
    key: 'lastExportAtArvalBE',
    visible: false,
  },
  {
    key: 'lastExportAtArvalPL',
    visible: false,
  },
  {
    key: 'lastExportAtArvalNL',
    visible: false,
  },
  {
    key: 'lastExportAtArvalFI',
    visible: false,
  },
  {
    key: 'lastExportAtOtoMoto',
    visible: false,
  },
  {
    key: 'lastExportAtArvalPT',
    visible: false,
  },
  {
    key: 'lastExportAtArvalBeReleaseEnglish',
    visible: false,
  },
  {
    key: 'lastExportAtArvalBeReleaseFrench',
    visible: false,
  },
  {
    key: 'lastExportAtArvalBeReleaseNiderland',
    visible: false,
  },
  {
    key: 'lastExportAtArvalSE',
    visible: false,
  },
  {
    key: 'lastExportAtArvalDE',
    visible: false,
  },
  {
    key: 'lastExportAtArvalTR',
    visible: false,
  },
  {
    key: 'lastExportAtBrokersFR',
    visible: false,
  },
];

export const OFFERS_SEARCH_TABLE_COLUMNS = [
  {
    head: 'table_id',
    dataPath: 'id',
  },
  {
    head: 'Type',
    dataPath: 'type',
  },
  {
    head: 'edit_offer_vehicle_make',
    dataPath: 'make',
  },
  {
    head: 'edit_offer_vehicle_model',
    dataPath: 'model',
  },
  {
    head: 'edit_offer_vehicle_vin',
    dataPath: 'vin',
  },
  {
    head: 'edit_offer_vehicle_registration',
    dataPath: 'registrationNumber',
  },
  {
    head: 'edit_offer_finance_sale_price',
    dataPath: 'salePrice',
  },
  {
    head: 'table_mt_event',
    dataPath: 'motorTradeEventName',
  },
  {
    head: 'table_created_by',
    dataPath: 'createdBy',
  },
  {
    head: 'table_created_at',
    type: 'date-tooltip',
    dataPath: 'createdAt',
  },
  {
    head: 'table_first_publish',
    type: 'date-tooltip',
    dataPath: 'firstExportAt',
  },
  {
    head: 'table_marketing_label',
    dataPath: 'marketingLabel',
  },
  {
    head: 'table_reservation_label',
    dataPath: 'reservationLabel',
  },
  {
    head: 'table_photos',
    dataPath: 'numberOfPictures',
  },
  {
    head: 'table_status',
    dataPath: 'status',
  },
  {
    head: 'table_mileage',
    dataPath: 'mileage',
  },
  {
    head: 'table_original_location',
    dataPath: 'originalLocation',
  },
  {
    head: 'table_reserved_by',
    dataPath: 'reservedBy',
  },
];
